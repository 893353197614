<template>
    <v-menu offset-y>
        <template v-slot:activator="{ attrs, on }">
            <c-btn
                class="mx-3 px-14 button"
                :class="{ 'app-bar-icon-color': !color }"
                dark
                v-bind="attrs"
                icon
                x-large
                width="90px"
                :color="color"
                v-on="on"
            >
                <v-icon>mdi-web</v-icon>
                {{ getLangToShow }}
            </c-btn>
            <!--            <v-chip
                pill
                class="transparent py-5"
                v-bind="attrs"
                v-on="on"
            >-->
            <!--                {{ getLang }}-->

            <!--                <img
                    :src="require(`@/assets/img/flags/1x1/${getLang}.svg`)"
                    alt=""
                    width="30px"
                >-->
            <!--                <v-icon
                    right
                    size="23"
                    class="transparent ml-1"
                >
                    mdi-web
                </v-icon>-->
            <!--            </v-chip>-->
            <!--            <v-btn
                text

                v-bind="attrs"
                rounded
                v-on="on"
            >
                {{ getLang }}
                <v-icon
                    right
                    size="23"
                    style="color: rgba(0, 0, 0, 0.54);"
                >
                    mdi-web
                </v-icon>
            </v-btn>-->
        </template>

        <v-list>
            <v-list-item
                v-for="item in getLanguages"
                :key="item.value"
                link
                @click="changeLanguage(item)"
            >
                <v-list-item-title v-text="item.name" />
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
    name: 'LanguageSelector',
    props: {
        color: {
            type: String
        }
    },
    data: () => ({
        text: 'Language'
    }),
    mounted() {
        this.fetchLanguages();
    },
    methods: {
        ...mapActions('languages', ['fetchLanguages']),

        async changeLanguage(item) {
            // this.$vuetify.lang.current = item.value;
            this.$root.$i18n.locale = item.code;
            await this.$store.dispatch('languages/updateLanguage', { language: item.code });

            window.location.reload();
        }
    },
    computed: {
        ...mapGetters('languages', ['getLang', 'getLangToShow', 'getLanguages'])
    }
};
</script>

<style lang="scss" scoped>
.button {
    :deep(.v-btn__content) {
        text-transform: none !important;
    }
}
</style>
